import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import { ActionsField } from '@/modules/process-editor/fields/actions-fields';
import { ActionTypeField } from '@/modules/process-editor/fields/action-type-fields';
import { FileStatusField } from '@/modules/process-editor/fields/file-status-fields';
import { FileTypeField } from '@/modules/process-editor/fields/file-type-fields';
import BooleanField from '@/shared/fields/boolean-field';
import { FunctionTypeField } from '@/modules/process-editor/fields/function-type-fields';
import { ApplicationStatusField } from '../fields/application-status-fields';
import { ExtraDocumentTemplateField } from '../fields/extra-document-template-fields';
import { ExtraDocumentVisibilityField } from '../fields/extra-document-visibility-fields';

const label = (name) => i18n.t(`PROCESS_EDITOR.STEPS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`PROCESS_EDITOR.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`PROCESS_EDITOR.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`)
const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField(
        'name',
        label('name'),
        { required: true},
        { placeholder:  placeholder('name')}
    ),
    next_step: new StringField(
        'next_step',
        label('next_step'),
        {},
        { placeholder:  placeholder('next_step')}
    ),
    conditional_routing: new EnumField(
        'conditional_routing',
        label('conditional_routing'),
        [
            { value: 'yes', text: enumLabel('status', 'yes'), type: 'primary', name: 'Da' },
            { value: 'no', text: enumLabel('status', 'no'), type: 'danger' , name: 'Nu'},
        ],
        {},
        { placeholder: placeholder('conditional_routing')},
    ),
    actionType: ActionTypeField.relationToOne(
        'actionType',
        label('action_type'),
        {},
        { placeholder: placeholder('action_type')},
    ),
    key: ActionsField.relationToOne(
        'key',
        label('action_name'),
        {required: true},
        { placeholder: placeholder('action_name')},
        [
            {
                key: 'variables',
                name: 'file_status'
            },
            {
                key: 'variables',
                name: 'file_type'
            },
            {
                key: 'variables',
                name: 'function_type'
            },
            {
                key: 'outputVariables',
                name: 'application_status'
            }
        ]
    ),
    file_status: FileStatusField.relationToOne(
        'file_status',
        label('file_status'),
        {required: true},
        { placeholder: placeholder('file_status')},
    ),
    file_type: FileTypeField.relationToOne(
        'file_type',
        label('file_type'),
        {required: true},
        { placeholder: placeholder('file_type')},
    ),
    extra_document_template_id: ExtraDocumentTemplateField.relationToOne(
        'extra_document_template_id',
        label('extra_document_template_id'),
        { autocompleteRequired: true },
        { placeholder: placeholder('extra_document_template_id')},
    ),
    extra_document_visibility: ExtraDocumentVisibilityField.relationToOne(
        'extra_document_visibility',
        label('extra_document_visibility'),
        {},
        { placeholder: placeholder('extra_document_visibility')},
    ),
    function_type: FunctionTypeField.relationToOne(
        'function_type',
        label('function_type'),
        { required: true },
        { placeholder: placeholder('function_type')}
    ),
    sign_by_assigned_user: new BooleanField(
        'sign_by_assigned_user',
        label('sign_by_assigned_user'),
    ),
    application_status: ApplicationStatusField.relationToOne(
        'application_status',
        label('application_status'),
        {required: true},
        { placeholder: placeholder('application_status')},
    ),
    return_action_id: ActionsField.relationToOne(
        'return_action_id',
        label('return_action'),
        {},
        { placeholder: placeholder('action_name')},
    )
};

export class ProcessStageModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
